import Consts from './consts';
import Utils from './utils';


/**
 * storage delegate
 */
class StorageDelegate {
	private name: string;
	private storage: Storage;

	constructor(name: string, storage: Storage) {
		this.name = name;
		this.storage = storage;
	}
	set(key: string, value: any): this {
		const json = this.current();
		json[key] = value;
		this.storage.setItem(this.name, JSON.stringify(json));
		return this;
	}
	get(key: string): any {
		return this.current()[key];
	}
	remove(key: string): this {
		const current = this.current();
		delete current[key];
		this.storage.setItem(this.name, JSON.stringify(current));
		return this;
	}
	/**
	 * get current storage values, returns {} if nothing
	 */
	current(): any {
		const currentValue = this.storage.getItem(this.name);
		return currentValue ? JSON.parse(currentValue) : {};
	}
	clear(): this {
		this.storage.removeItem(this.name);
		return this;
	}
}

/**
 * storage proxy, to session storage and local storage
 */
class StorageProxy {
	private sessionStorage: StorageDelegate;
	private localStorage: StorageDelegate;

	constructor(name: string) {
		this.sessionStorage = new StorageDelegate(name, window.sessionStorage);
		this.localStorage = new StorageDelegate(name, window.localStorage);
	}
	session(): StorageDelegate {
		return this.sessionStorage;
	}
	local(): StorageDelegate {
		return this.localStorage;
	}
	/**
	 * 先从session storage拿, 没有再从local storage拿
	 */
	get(key: string): any {
		return this.session().get(key) || this.local().get(key);
	}
	/**
	 * 同时设置session storage和local storage
	 */
	set(key: string, value: any): this {
		this.session().set(key, value);
		this.local().set(key, value);
		return this;
	}
	/**
	 * 从session storage和local storage中同时移除
	 */
	remove(key: string): this {
		this.session().remove(key);
		this.local().remove(key);
		return this;
	}
	/**
	 * 同时清除session storage和local storage中
	 */
	clear(): this {
		this.session().clear();
		this.local().clear();
		return this;
	}
}

/**
 * theme storage
 */
class ThemeStorage extends StorageProxy {
	constructor() {
		super('CSMS-Theme');
	}
	set(key: string, value: any): this {
		this.session().set(key, value);

		// 本地调试不写入local storage
		if (process.env.REACT_APP_ENV_NAME !== 'LOCAL') {
			this.local().set(key, value);
		}
		return this;
	}
}

/**
 * profile storage
 */
class ProfileStorage extends StorageProxy {
	constructor() {
		super('CSMS-Profile');
	}
	set(key: string, value: any): this {
		this.session().set(key, value);

		// 本地调试不写入local storage
		if (process.env.REACT_APP_ENV_NAME !== 'LOCAL') {
			this.local().set(key, value);
		}
		return this;
	}
	get(key: string): any {
		const value = super.get(key);
		if (key === Consts.SHORTCUTS_FLOATING_KEY) {
			return value === 'true' || value === true;
		} else {
			return value;
		}
	}
	isShortcutsFloating(): boolean {
		return this.get(Consts.SHORTCUTS_FLOATING_KEY);
	}
}

class ToDetailStorage extends StorageProxy {
	DATA = 'data';
	OPERATION = 'operation';
	SCROLL_TOP = 'scrollTop';

	constructor() {
		super('CSMS-To-Detail');
	}
	set(key: string, value: any): this {
		this.session().set(key, value);
		return this;
	}
	get(key: string): any {
		return this.session().get(key);
	}
}

class SearchHistoryStorage extends StorageProxy {
	PRODUCT = 'product';

	constructor() {
		super('CSMS-Search');
	}
	set(key: string, value: any): this {
		if (key === this.PRODUCT) {
			const exists: any = this.get(key);
			// 如果关键字已经存在, 去掉原有的
			// eslint-disable-next-line
			const index = exists.findIndex((item: any) => item == value);
			if (index !== -1) {
				exists.splice(index, 1);
			}
			// 加到最前面
			exists.splice(0, 0, value);
			if (exists.length > 5) {
				exists.length = 5;
			}
			this.local().set(key, exists);
		} else {
			this.local().set(key, value);
		}
		return this;
	}
	get(key: string): any {
		if (key === this.PRODUCT) {
			const exists = this.local().get(key);
			return exists == null ? [] : exists;
		} else {
			return this.local().get(key);
		}
	}
	removeItem(key: string, value: any): this {
		// eslint-disable-next-line
		if (key == this.PRODUCT) {
			// eslint-disable-next-line
			this.local().set(key, this.get(key).filter((item: any) => item != value));
		}
		return this;
	}
}

class ShareStorage extends StorageProxy {
	static FIRST_URL = 'first-url';
	static THIRD_DESK_HOME = 'thirdDeskHome';//第三方主页
	static THIRD_DESK_LOGIN = 'thirdDeskLogin';//第三方登陆页面

	constructor() {
		super('CSMS-Share');
	}
	holdFirstUrl(): string {
		const location = window.location;
		let url = `${location.origin}${location.pathname}${location.search}`;
		if (!this.session().get(ShareStorage.FIRST_URL)) {
			this.session().set(ShareStorage.FIRST_URL, url);
		}
		const firstUrl = this.session().get(ShareStorage.FIRST_URL);
		if (url.split('#')[0] !== firstUrl.split('#')[0]) {
			this.session().set(ShareStorage.FIRST_URL, url);
		}
		return url;
	}
	getFirstUrl(): string {
		return this.session().get(ShareStorage.FIRST_URL);
	}

	holderThirdDeskHome(): string | undefined {
		const queryString = Utils.fromQueryString();
		const thirdDeskHome = queryString.thirdDeskHome;
		if (!this.local().get(ShareStorage.THIRD_DESK_HOME) && thirdDeskHome) {
			this.local().set(ShareStorage.THIRD_DESK_HOME, thirdDeskHome)
		}
		return thirdDeskHome;
	}

	removeThirdDeskHome() {
		return this.remove(ShareStorage.THIRD_DESK_HOME);
	}

	getThirdDeskHome(): string {
		return this.local().get(ShareStorage.THIRD_DESK_HOME);
	}

	holderThirdDeskLogin(): string | undefined {
		const queryString = Utils.fromQueryString();
		const thirdDeskLogin = queryString.thirdDeskLogin;
		if (!this.local().get(ShareStorage.THIRD_DESK_LOGIN) && thirdDeskLogin) {
			this.local().set(ShareStorage.THIRD_DESK_LOGIN, thirdDeskLogin)
		}
		return thirdDeskLogin;
	}

	removeThirdDeskLogin() {
		return this.remove(ShareStorage.THIRD_DESK_LOGIN);
	}

	getThirdDeskLogin(): string {
		return this.local().get(ShareStorage.THIRD_DESK_LOGIN);
	}

	removeThirdDesk() {
		this.removeThirdDeskHome();
		this.removeThirdDeskLogin();
	}
}

class AdviceDataStorage extends StorageProxy {
	constructor() {
		super('CSMS-Advice-Data');
	}

	// set(key: string, value: any): this {
	// 	//设置建议书数据时同时设置回朔数据
	// 	try {
	// 		//记录TRACE_NO
	// 		//@ts-ignore
	// 		GluttonData.session().set(Consts.GLUTTON_TRACE_NO, window.GluttonContext['traceNo']);
	// 	} catch (e) {
	// 		console.error(e);
	// 	}
	// 	return super.set(key, value);
	// }
}


class PolicyDataStorage extends StorageProxy {
	constructor() {
		super('CSMS-Policy-Data');
	}

	// set(key: string, value: any): this {
	// 	//设置建议书数据时同时设置回朔数据
	// 	try {
	// 		//记录TRACE_NO
	// 		//@ts-ignore
	// 		GluttonData.session().set(Consts.GLUTTON_TRACE_NO, window.GluttonContext['traceNo']);
	// 	} catch (e) {
	// 		console.error(e);
	// 	}
	// 	return super.set(key, value);
	// }
}


// initialize storages
const Auth = new StorageProxy('CSMS-Auth');
const Account = new StorageProxy('CSMS-Account');
const Env = new StorageProxy('CSMS-Env');
const Theme = new ThemeStorage();
const ToDetail = new ToDetailStorage();
const Profile = new ProfileStorage();
const Search = new SearchHistoryStorage();
const Share = new ShareStorage();
const AdviceData = new AdviceDataStorage();
const PolicyData = new PolicyDataStorage();
const GluttonData = new StorageProxy('CSMS-Glutton-Data');

export default {
	Auth,
	Account,
	Env,
	Theme,
	ToDetail,
	Profile,
	Search,
	Share,
	AdviceData,
	PolicyData,
	GluttonData,
	/**
	 * session storage全部清空
	 * local storage部分清空
	 */
	clear: (): void => {
		window.sessionStorage.clear();
		Auth.local().clear();
		Env.local().clear();
		AdviceData.local().clear();
		Profile.local().clear();
	}
};
